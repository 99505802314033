import React from 'react'
import {
    abbreviationToNameMap,
    SportName,
    TabtouchMatchedOdds,
} from '@the-game/api-interfaces'
import { VisuallyHidden } from '../VisuallyHidden'
import { TabTouchLogo } from '../logos/TabTouchLogo'
import { Box } from '../Box'
import styled from '@emotion/styled'
import { calcRem } from '@the-game/components/utils'
import { breakpointMax, getElevationBySize } from '@the-game/components/utils'
import { useGameContext } from '@the-game/web-common'

export interface BettingOddsProps {
    matchOdds: TabtouchMatchedOdds | undefined
    isLocked: boolean
    isEighteenOrOver: boolean
    tabTouchLinkPressed: (type: 'single' | 'logo', team?: string) => void
}

const oddsCurrencyFormat = Intl.NumberFormat('en-au', {
    currency: 'aud',
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
})

export const buildTabtouchUtmParams = (sport: SportName, season: string) => {
    const utmCampaignParams = new URLSearchParams({
        utm_campaign: `game${sport.toLowerCase()}${season}`,
        utm_source: 'SWM',
        utm_medium: 'website',
        utm_content: 'thegamebuttons',
    })

    return utmCampaignParams.toString()
}

export function BettingOdds({
    isLocked,
    isEighteenOrOver,
    matchOdds,
    tabTouchLinkPressed,
}: BettingOddsProps) {
    const { sport, season } = useGameContext()

    const utmParams = buildTabtouchUtmParams(sport, season)
    if (!matchOdds || isLocked || !isEighteenOrOver || sport === 'Cricket') {
        // NB Disabled Odds as we have no betting partner for Cricket :not-stonks:
        return null
    }

    const {
        homeTeam,
        homeOdds,
        homeBetLink,
        awayTeam,
        awayOdds,
        awayBetLink,
        matchBetLink,
    } = matchOdds

    const homeTeamName = abbreviationToNameMap[homeTeam]
    const homeTeamOddsFormatted =
        homeOdds > 0 ? oddsCurrencyFormat.format(homeOdds) : null

    const awayTeamName = abbreviationToNameMap[awayTeam]
    const awayTeamOddsFormatted =
        awayOdds > 0 ? oddsCurrencyFormat.format(awayOdds) : null

    return (
        <React.Fragment>
            <VisuallyHidden>{`${homeTeamName} odds are ${homeTeamOddsFormatted} and ${awayTeamName} odds are ${awayTeamOddsFormatted}`}</VisuallyHidden>
            <StyledOddsContainer elevation={2}>
                <OddsBadge
                    href={`${homeBetLink}&${utmParams}`}
                    odds={homeTeamOddsFormatted}
                    teamName={homeTeamName}
                    onClick={() => tabTouchLinkPressed('single', homeTeam)}
                />
                <OddsBadge
                    href={`${awayBetLink}&${utmParams}`}
                    odds={awayTeamOddsFormatted}
                    teamName={awayTeamName}
                    onClick={() => tabTouchLinkPressed('single', awayTeam)}
                />
                <StyledTabLogo
                    href={`${matchBetLink}&${utmParams}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => tabTouchLinkPressed('logo')}
                >
                    <VisuallyHidden>
                        See more at TABtouch (opens in new window)
                    </VisuallyHidden>
                    <TabTouchLogo />
                </StyledTabLogo>
            </StyledOddsContainer>
        </React.Fragment>
    )
}

const StyledOddsAnchor = styled('a')({
    textDecoration: 'none',
})

const StyledOdds = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>(({ hidden, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: calcRem(167),
    maxWidth: calcRem(167),
    height: calcRem(32),
    backgroundColor: hidden
        ? theme.colors.palette.neutral30
        : theme.colors.white,
    borderRadius: calcRem(12),
    justifyContent: 'center',
    [breakpointMax('sm')]: {
        minWidth: calcRem(76),
        maxWidth: calcRem(76),
    },

    ...(hidden === false && getElevationBySize(1)),
    '&:hover': {
        ...(hidden === false && getElevationBySize(2)),
        fontWeight: theme.weights.medium,
    },
}))

const StyledOddsText = styled('span')(({ theme }) => ({
    fontSize: calcRem(14),
    lineHeight: calcRem(16),
    color: theme.colors.palette.neutral90,
}))

interface OddsBadgeProps {
    odds: string | null
    href: string | undefined
    teamName: string | null
    onClick: () => void
}
function OddsBadge({ odds, href, teamName, onClick }: OddsBadgeProps) {
    const content = (
        <StyledOdds hidden={odds === null}>
            {odds !== null && (
                <>
                    <VisuallyHidden>
                        Place a Head to Head bet for {teamName} with TABtouch
                        (opens in new window). A $1 bet could win{' '}
                    </VisuallyHidden>
                    <StyledOddsText data-testid="odds">{odds}</StyledOddsText>
                </>
            )}
        </StyledOdds>
    )

    return href ? (
        <StyledOddsAnchor
            href={href}
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
        >
            {content}
        </StyledOddsAnchor>
    ) : (
        content
    )
}

const StyledOddsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'none',
    padding: calcRem(8),
    borderRadius: `0px 0px ${calcRem(16)} ${calcRem(16)}`,
    backgroundColor: theme.colors.palette.neutral30,

    '& > *': {
        flexBasis: 64,
    },

    // Needs to mimic the change the containing card itself uses
    '@media (max-width: 400px)': {
        borderRadius: `0px 0px ${calcRem(8)} ${calcRem(8)}`,
    },

    // For sceen-reader order, we want "Place a bet on Home", "Place a bet on Away", "See more at TABtouch"
    // but display is Home, TABtouch, Away
    '& > *:first-child': {
        order: 1,
    },
    '& > *:nth-child(2)': {
        order: 3,
    },
    '& > *:nth-child(3)': {
        order: 2,
    },
}))

const StyledTabLogo = styled('a')({
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 96,
    lineHeight: 1,
})
